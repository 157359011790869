import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ConfirmItemsList } from "./components/confirm-items-list/ConfirmItemsList";
import useGeneratePrintTagPayload from "../../hooks/use-generate-print-tag-payload";
import { useAppConfigProvider } from "../../services/soft-cache-service";
import { useXemelgoClient } from "../../services/xemelgo-service";
import usePrintService from "../../hooks/use-print-service";
import { PRINT_TYPES } from "../../data/constants";
import { pluralizeWord } from "../../common/Utilities";
import Modal from "../../components/modal";
import PrinterSelectionComponent from "../../components/printer-selection-component";
import Style from "./PrintTagsModal.module.css";
import augmentItemFields from "./utils/augment-item-fields";
import SearchDropdown from "../../components/SearchDropdown/SearchDropdown";
import { APP_ID_TO_FEATURE_ID } from "../../constants/tabConfigConstants";
import { APP_ID_TO_REPRINT_FEATURE_ID, USER_EVENT_BATCH_SIZE } from "./data/constants";

export const PrintTagsModal = ({
  selectedItems,
  onCloseModal,
  printTagsOptions,
  appId,
  solutionDisplayName = appId
}) => {
  const addFeatureId = APP_ID_TO_FEATURE_ID[appId];
  const reprintFeatureId = APP_ID_TO_REPRINT_FEATURE_ID[appId];

  const configProvider = useAppConfigProvider(appId);
  const client = useXemelgoClient();

  const {
    customTemplate: addTemplate,
    tagConfig,
    autoPublishAfterPrintLocationId = ""
  } = configProvider.getValue(addFeatureId, "object") || {};
  const {
    label,
    customTemplate: reprintTemplate,
    showLocationDropdown,
    locationCategories = [],
    itemFieldUpdates
  } = printTagsOptions;

  const { generatePrintCommandBySolution } = useGeneratePrintTagPayload(appId, PRINT_TYPES.ZPL);
  const printService = usePrintService(true, PRINT_TYPES.ZPL, reprintTemplate || addTemplate, reprintFeatureId);

  const { print, isPrintReady, selectedTemplateConfig } = printService;

  const [isPrinting, setIsPrinting] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    if (showLocationDropdown && locationCategories.length) {
      const locationClient = client.getLocationClient();
      const newLocations = (await locationClient.getLocationsOfCategory(locationCategories)).map((location) => {
        return {
          key: location.id,
          label: location.identifier || location.name,
          value: location.id
        };
      });

      setLocations(newLocations);
    }
  };

  const onPrintFinish = async (itemIds, trackingSessionIds, sensorProfileIds) => {
    const publishClient = client.getPublishClient();

    if (selectedLocation?.value || autoPublishAfterPrintLocationId) {
      if (trackingSessionIds.length) {
        await publishClient.userEvent(
          trackingSessionIds,
          selectedLocation?.value || autoPublishAfterPrintLocationId,
          undefined,
          USER_EVENT_BATCH_SIZE
        );
      } else if (sensorProfileIds.length) {
        await publishClient.publishUserEventWithSensorProfile(
          sensorProfileIds,
          selectedLocation?.value || autoPublishAfterPrintLocationId,
          undefined,
          USER_EVENT_BATCH_SIZE
        );
      }
    }

    if (itemIds.length && itemFieldUpdates) {
      // To be able to update a field with a timestamp to track when the tag was printed
      Object.entries(itemFieldUpdates).forEach(([fieldId, value]) => {
        if (value?.type === "timestamp") {
          itemFieldUpdates[fieldId] = Date.now();
        }
      });

      const updates = itemIds.map((itemId) => {
        return {
          id: itemId,
          fields: itemFieldUpdates
        };
      });

      await publishClient.bulkUpdate(updates);
    }
  };

  const submitPrintTags = async () => {
    setIsPrinting(true);
    const itemIds = [];
    const trackingSessionIds = [];
    const sensorProfileIds = [];

    for (const item of selectedItems) {
      const itemToPrint = augmentItemFields(item);

      const printPayload = await generatePrintCommandBySolution(itemToPrint, {
        tagConfig,
        ...selectedTemplateConfig
      });

      try {
        await print(printPayload);

        itemIds.push(item.id);
        trackingSessionIds.push(item.trackingSessionId);

        if (item.sensorProfile?.id) {
          sensorProfileIds.push(item.sensorProfile.id);
        }
      } catch (e) {
        await onPrintFinish(itemIds, trackingSessionIds, sensorProfileIds);
        onCloseModal(true, false, "Could not print tags.");
        return;
      }
    }

    await onPrintFinish(itemIds, trackingSessionIds, sensorProfileIds);
    onCloseModal(true, true, `Tags have been submitted for printing.`, !!autoPublishAfterPrintLocationId);
  };

  return (
    <Modal
      title={label || "Print Tags"}
      onCancel={() => {
        onCloseModal(false, false, "");
      }}
      onConfirm={submitPrintTags}
      confirmDisabled={isPrinting || !isPrintReady}
    >
      <p className={Style.modal_text}>
        {`You are about to print tags for the selected ${pluralizeWord(solutionDisplayName)}.
             Please select your printer and confirm the ${pluralizeWord(solutionDisplayName)} 
             you would like to print tags for.`}
      </p>
      <PrinterSelectionComponent
        printService={printService}
        solutionType={reprintFeatureId}
        hideLabelSelection={reprintTemplate || addTemplate || tagConfig}
        showTitle={false}
      />
      {showLocationDropdown && (
        <div className={Style.location_dropdown}>
          Location:
          <SearchDropdown
            selectedItem={selectedLocation}
            showIcon
            placeholder="Select a location"
            options={locations}
            onItemSelected={(event) => {
              setSelectedLocation(event);
            }}
            dropdownMenuClassName={Style.dropdown_menu}
          />
        </div>
      )}
      <ConfirmItemsList
        items={selectedItems.map((item) => {
          return item.identifier || item.name || item.sensorProfileVid || item.rfidSerial;
        })}
        solutionDisplayName={solutionDisplayName}
      />
    </Modal>
  );
};

PrintTagsModal.defaultProps = {
  printTagsOptions: {}
};

PrintTagsModal.propTypes = {
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  onCloseModal: PropTypes.func.isRequired,
  printTagsOptions: PropTypes.shape({
    label: PropTypes.string,
    customTemplate: PropTypes.string,
    showLocationDropdown: PropTypes.bool,
    locationCategories: PropTypes.arrayOf(PropTypes.string),
    itemFieldUpdates: PropTypes.shape({
      [PropTypes.string]: PropTypes.shape({
        value: PropTypes.any,
        action: PropTypes.string
      })
    })
  }),
  appId: PropTypes.string.isRequired,
  solutionDisplayName: PropTypes.string
};
