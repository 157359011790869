import _ from "lodash";
import { PRINT_TYPES } from "../../data/constants";
import generateBartenderPayload from "./utils/generate-bartender-payload";
import getZPLCommandWithCustomTemplate from "./utils/get-zpl-command-with-custom-template";
import getZPLCommandWithDefaultTemplate from "./utils/get-zpl-command-with-default-template";
import generateBartenderCsv from "../../utils/generate-bartender-csv";

const SOLUTIONS = {
  WORK_ORDER: "workOrder",
  ORDER: "order",
  INVENTORY: "inventory",
  ASSET: "asset"
};

/**
 * @typedef {Object} BartenderConfig
 * @property {string} printerName
 * @property {string} templateFilePath
 * @property {{[propertyName: string]: string}} propertiesToHeaderMap
 */

/**
 * @typedef {string} ZPLConfig
 */

export const useGeneratePrintTagPayload = (solution, printType) => {
  // work order
  const generatePrintWorkOrderTagCommand = (workOrder, labelConfig) => {
    const { customTemplate, tagConfig } = labelConfig || {};

    if (customTemplate) {
      return getZPLCommandWithCustomTemplate(workOrder, customTemplate);
    }
    return getZPLCommandWithDefaultTemplate(workOrder, tagConfig);
  };

  // inventory
  const generatePrintInventoryTagCommand = async (labelPropertiesMap, labelConfig) => {
    const clonedLabelPropertiesMap = _.cloneDeep(labelPropertiesMap);
    const { customTemplate } = labelConfig || {};

    let template =
      "^XA^RS8^FO200,70^A0N,50^FD${name || ''}^FS^FO200,140^A0N,50^FD${color_ts || ''}^FS^FO200,210^A0N,50^FD${sku_ts || ''}^FS^FO200,400^BY4^BCI,100,Y,N,N^FD${tracker_serial || ''}^FS^FO1000,70^BQN,2,6^FDMM,A${tracker_serial || ''}^FS^RFW,H^FD${tracker_serial || ''}^FS^XZ";

    if (customTemplate) {
      template = customTemplate;
    }
    return getZPLCommandWithCustomTemplate(clonedLabelPropertiesMap, template);
  };

  // asset
  const generatePrintAssetTagCommand = async (labelPropertiesMap, labelConfig) => {
    const clonedLabelPropertiesMap = _.cloneDeep(labelPropertiesMap);
    const { customTemplate = "" } = labelConfig;

    return getZPLCommandWithCustomTemplate(clonedLabelPropertiesMap, customTemplate);
  };

  /**
   *
   * @param {{[propertyName: string]: string}[]} labelPropertiesMap
   * @param {LabelConfig} labelConfig
   * @returns {Promise<string>}
   */
  const generatePrintCommandBySolution = async (labelPropertiesMap, labelConfig) => {
    switch (printType) {
      case PRINT_TYPES.BARTENDER:
        return generateBartenderPayload(labelPropertiesMap, labelConfig);
      case PRINT_TYPES.ZPL:
        switch (solution) {
          case SOLUTIONS.ASSET:
            return generatePrintAssetTagCommand(labelPropertiesMap, labelConfig);
          case SOLUTIONS.INVENTORY:
            return generatePrintInventoryTagCommand(labelPropertiesMap, labelConfig);
          case SOLUTIONS.WORK_ORDER:
          case SOLUTIONS.ORDER:
            return generatePrintWorkOrderTagCommand(labelPropertiesMap, labelConfig);
          default:
            return null;
        }
      default:
        return null;
    }
  };

  /**
   *
   * @param {{[propertyName: string]: string}[]} labelPropertiesMapList
   * @param {BartenderConfig | ZPLConfig} labelConfig
   * @returns {Promise<any[]>} list of payloads to be sent to printer
   */
  const generateMultiplePrintCommands = async (labelPropertiesMapList, labelConfig) => {
    if (printType === PRINT_TYPES.BARTENDER_UPLOAD) {
      return [generateBartenderCsv(labelPropertiesMapList, labelConfig)];
    }

    return Promise.all(
      labelPropertiesMapList.map((labelPropertiesMap) => {
        return generatePrintCommandBySolution(labelPropertiesMap, labelConfig);
      })
    );
  };

  return { generateMultiplePrintCommands, generatePrintCommandBySolution };
};
